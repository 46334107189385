@font-face {
  font-family: 'KL';
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 125 950;
  src: url('Karla-Variable.woff2') format('woff2-variations');
}

@font-face {
  font-family: "ASC";
  font-style: normal;
  font-weight: 400;
  src: url("AmaticSC-Regular.woff2") format("woff2"), url("AmaticSC-Regular.woff") format("woff");
}
